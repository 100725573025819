import { render, staticRenderFns } from "./lotto-listV2.vue?vue&type=template&id=52c900dd&scoped=true&"
import script from "./lotto-listV2.vue?vue&type=script&lang=js&"
export * from "./lotto-listV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c900dd",
  null
  
)

export default component.exports